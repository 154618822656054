'use client';

import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { toClassName } from 'helpers';
import { handleUseStrapiImageUrl } from 'helpers/strapi';
import { TNextClientComponent } from 'types/next';
import IconElement from 'components/Icon/IconElement';
import { styles } from '.';
type TProps = {
  projects: any[];
  lang: string;
};
const HighlightSection: TNextClientComponent<TProps> = props => {
  const {
    projects,
    lang
  } = props;
  const [autoSelector, setAutoSelector] = useState<number | null>(0);
  const [manualSelector, setManualSelector] = useState<number | null>(null);
  useEffect(() => {
    if (autoSelector === null) {
      return;
    }
    if (projects?.length === 1) {
      return;
    }
    const interval = setInterval(() => {
      if (autoSelector < projects?.length - 1) {
        setAutoSelector(autoSelector => autoSelector !== null ? autoSelector + 1 : 0);
        return;
      }
      setAutoSelector(0);
    }, 6000);
    return () => clearInterval(interval);
  }, [projects?.length, autoSelector]);
  return <div className={styles.root} id="header" style={{
    backgroundImage: `url('${handleUseStrapiImageUrl(projects?.[manualSelector ?? autoSelector ?? 0].image.url)}')`
  }} data-sentry-component="HighlightSection" data-sentry-source-file="HighlightSection.tsx">
      <div className={styles.pattern} />
      <div className={styles.container}>
        <div className={styles.content} style={{
        zIndex: 1
      }}>
          <ul>
            {projects?.map((project, index) => <li key={project.id}>
                <Link href={`/${lang}/projects/${project.slug}`}>
                  <div onMouseEnter={() => {
                setManualSelector(index);
                setAutoSelector(null);
              }} onMouseLeave={() => {
                setManualSelector(null);
                setAutoSelector(index);
              }} className={toClassName(styles.title.base, index === manualSelector || index === autoSelector ? styles.title.active : styles.title.inactive)}>
                    <p>{project.projectName}</p>
                    <p className="font-nbi-mono text-[0.45rem] sm:text-sm leading-3">
                      {project.year}
                    </p>
                  </div>
                </Link>
              </li>)}
          </ul>
          <IconElement type="sharp-light" accessor="arrow-down-long" className="text-2xl" data-sentry-element="IconElement" data-sentry-source-file="HighlightSection.tsx" />
        </div>
      </div>
    </div>;
};
export default HighlightSection;